import { Component, Input } from "@angular/core";
import { QLSuggestion } from "./s25ql.suggest";
import { S25Const } from "../../util/s25-const";
import { Item } from "../../pojo/Item";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ql-search-input-suggestions")
@Component({
    selector: "s25-ng-ql-search-input-suggestions",
    template: `
        <ng-container [ngSwitch]="suggestion.type">
            <s25-generic-dropdown
                *ngSwitchCase="'option'"
                [items]="suggestion.data.options"
                [autoOpen]="true"
                [alwaysOpen]="true"
                (chosenChange)="suggestion.onSelect($event)"
            >
            </s25-generic-dropdown>
            <s25-ng-generic-multiselect-dropdown
                *ngSwitchCase="'options'"
                [items]="suggestion.data.options"
                [autoOpen]="true"
                [alwaysOpen]="true"
                (chosenChange)="suggestion.onSelect($event)"
            ></s25-ng-generic-multiselect-dropdown>
            <s25-ng-dropdown-search-criteria
                *ngSwitchCase="'object'"
                [type]="suggestion.data.objectType"
                [autoOpen]="true"
                [customFilterValue]="suggestion.data.customFilterValue"
                (chosenChange)="suggestion.onSelect($event)"
            ></s25-ng-dropdown-search-criteria>
            <s25-ng-dropdown-multi-search-criteria
                *ngSwitchCase="'objects'"
                [type]="suggestion.data.objectType"
                [autoOpen]="true"
                [staticItems]="
                    suggestion.data.objectType === 'contacts' && [{ itemId: 0, itemName: '', favorite: 'T' }]
                "
                (chosenChange)="suggestion.onSelect($event)"
            ></s25-ng-dropdown-multi-search-criteria>
            <s25-ng-search-dropdown
                *ngSwitchCase="'search'"
                [hasFav]="true"
                [itemTypeId]="suggestion.data.searchType"
                [allowNonQueryId]="false"
                [searchEnabled]="true"
                [autoOpen]="true"
                (chosenChange)="suggestion.onSelect($event)"
            ></s25-ng-search-dropdown>
            <s25-custom-attr-dropdown-adv-search
                *ngSwitchCase="'attribute'"
                [itemTypeId]="type"
                (queryParamsChange)="suggestion.onSelect($event)"
            ></s25-custom-attr-dropdown-adv-search>
            <s25-ng-dropdown-multi-contacts
                *ngSwitchCase="'contactRole'"
                [type]="type"
                [autoOpen]="true"
                [alwaysOpen]="true"
                (done)="suggestion.onSelect($event)"
            ></s25-ng-dropdown-multi-contacts>
            <s25-ng-dropdown-multi-relationships
                *ngSwitchCase="'relationship'"
                [type]="$any(type)"
                [autoOpen]="true"
                [alwaysOpen]="true"
                [placeholder]="'Search ' + (S25Const.itemId2Name[type] || '')"
                (done)="suggestion.onSelect($event)"
            ></s25-ng-dropdown-multi-relationships>
            <s25-ng-ql-suggest-address
                *ngSwitchCase="'address'"
                (done)="suggestion.onSelect($event)"
                (click)="$event.stopPropagation()"
            ></s25-ng-ql-suggest-address>
        </ng-container>
    `,
})
export class S25qlSearchInputSuggestionsComponent {
    @Input() type: Item.Id;
    @Input() suggestion: QLSuggestion["suggestion"];

    S25Const = S25Const;
}
